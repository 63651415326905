import {CheckIcon, ChevronRightIcon, CloseIcon, Icon, SpinnerIcon} from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Flex, Heading,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, Textarea, useDisclosure,
    useToast
} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {useSession} from "../../hooks/session";
import {useEffect, useState} from "react";
import * as React from "react";
import {BsWhatsapp} from "react-icons/bs";
import SessionCancelModal from "../../components/sessionCancel";
import {getSessionStatusColor, getSessionStatusDescription, SessionStatus} from "../../enum/session/SessionStatus";
import Report from "../../components/report";
import {format} from "date-fns";
import api from "../../services/api";
import {CheckType} from "../../enum/session-check/CheckType";
import {phoneToWhatsApp} from "../../util/phone-number";
import {Simulate} from "react-dom/test-utils";
import copy from "copy-to-clipboard";
import { Rating } from '@smastrom/react-rating'

import '@smastrom/react-rating/style.css'
import Footer from "../../components/Footer";

interface DeliverablesProps {
}



const DeliverableView: React.FC<DeliverablesProps> = () => {
    const id = useParams().id;
    const sessionD = useSession(id);
    const session = sessionD?.data;


    const { isOpen, onOpen, onClose } = useDisclosure();
    const [notes, setNotes] = useState("");
    const [lacking_areas, setLacking_areas] = useState("");

    const toast = useToast();

    const [rating, setRating] = useState<number|null>(null) // Initial value

    const copylink = () => {
        copy('https://studio.piperz.io/login');
        toast({
            title: "link copiado.",
            position: "top-right",
            status: "success",
            duration: 16000,
            isClosable: true,
        });
    }

    const whatsAppInRoute = phoneToWhatsApp(
        String(session?.scheduling_responsible_info_phone),
        `Olá ${
            session?.scheduling_responsible_info
        },
      Já estou a caminho do seu imóvel.
      Até logo!`
    );
    const whatsAppContact = phoneToWhatsApp(
        String(session?.user?.phone),
        `Olá ${
            session?.user?.name
        }!`
    );

    useEffect(() => {
        console.log(session)
        if (sessionD?.status && sessionD?.status != 200) {
            toast({
                title: "Erro ao encontrar o ensaio.",
                position: "top-right",
                status: "error",
                duration: 16000,
                isClosable: true,
            });
        }
    }, [sessionD?.status])


    const handleCheckIn = () => {

        api.post(`/provider/session-reports-start`, { id: session?.id })
            .then((response) => {
                if (response.status == 201) {
                    window.location.reload();
                } else {
                    throw new Error();
                }
            })
            .catch(() => alert("Falha ao salvar alterações"));
    };

    const handleCheckOut = () => {
        api.post(`/provider/session-reports`, {
            session: {
                status: SessionStatus.PENDING,
                id: session?.id,
            },
            additional_info: { notes, lacking_areas, rating },
            check_type: CheckType.END_PROVIDER_SESSION,
        })
            .then((response) => {
                if (response.status == 201) {
                    window.location.reload();
                } else {
                    throw new Error();
                }
            })
            .catch(() => alert("Falha ao salvar alterações"));
    };
    return (
        <Box width={'100%'} maxWidth={'1700px'} mx={'auto'} p={'20px'}>

                <Flex mb={'4'}>
                    <Link to={"/"}>
                        <Flex gap={'1.5'} alignItems={'center'}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_2023_20012" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_2023_20012)">
                                    <path d="M9.04168 16.0417L3.60418 10.6251C3.50695 10.5278 3.43751 10.4271 3.39584 10.323C3.35418 10.2188 3.33334 10.1112 3.33334 10.0001C3.33334 9.88895 3.35418 9.78131 3.39584 9.67714C3.43751 9.57298 3.50695 9.47228 3.60418 9.37506L9.04168 3.93756C9.20834 3.77089 9.40973 3.68409 9.64584 3.67714C9.88195 3.6702 10.0903 3.757 10.2708 3.93756C10.4375 4.10423 10.5208 4.30909 10.5208 4.55214C10.5208 4.7952 10.4375 5.00006 10.2708 5.16673L6.31251 9.12506H15.4375C15.6736 9.12506 15.8785 9.21187 16.0521 9.38548C16.2257 9.55909 16.3125 9.76395 16.3125 10.0001C16.3125 10.2362 16.2257 10.441 16.0521 10.6146C15.8785 10.7883 15.6736 10.8751 15.4375 10.8751H6.31251L10.2708 14.8334C10.4375 15.0001 10.5243 15.198 10.5313 15.4271C10.5382 15.6563 10.4514 15.8612 10.2708 16.0417C10.1042 16.2084 9.89932 16.2917 9.65626 16.2917C9.41321 16.2917 9.20834 16.2084 9.04168 16.0417Z" fill="#4D5562"/>
                                </g>
                            </svg>
                            <Text fontWeight={'500'} fontSize={'md'}>Produção</Text>
                        </Flex>

                    </Link>
                </Flex>

            <Flex mb={'16'} flexDirection={'column'} shadow={'0px  0px 7px #97A4B6'} borderRadius={'2xl'} padding={'6'}>
                <Flex justifyContent={'space-between'}>
                    <Flex gap={2} alignItems={'start'} flexDirection={'column'}>
                        <Flex alignItems={'center'} gap={'2'}>
                            <Avatar name={session?.client.name} size={'md'} src={session?.client.logo}/>
                            <Text fontWeight={'500'} fontSize={'18px'} color={'gray.600'}>{session?.client.name}</Text>
                        </Flex>
                        <Text fontWeight={'500'} bg={getSessionStatusColor(Number(session?.status))} color={'white'} p={'0.5'} px={'4'} borderRadius={'2xl'}>{getSessionStatusDescription(Number(session?.status))}</Text>
                    </Flex>
                    <Button colorScheme={'whatsapp'} p={'0'} borderRadius={'3xl'}><BsWhatsapp width={'100%'} height={'100%'}/></Button>
                </Flex>

                <Divider my={'4'}/>

                <Flex>
                    <Flex flexDirection={'column'} gap={'0.5'} mt={'3'}>
                        <Text fontWeight={'500'} fontSize={'16px'} color={'gray.400'}>Agendamento</Text>

                        <Text mt={'3'} fontWeight={'500'} fontSize={'16px'}>{session?.scheduled_for && format(new Date(session?.scheduled_for), "HH:mm")} &bull; {session?.scheduled_for && new Date(session?.scheduled_for).toLocaleDateString(
                            "pt-BR",
                            {
                                day: "2-digit",
                                month: "2-digit",
                            }
                        )}</Text>
                        <Text fontWeight={'500'} fontSize={'14px'} color={'gray.600'}>ID {session?.id} &bull; Ref Int. {session?.session_vertical_data_reference}</Text>
                        <Text fontWeight={'400'} fontSize={'14px'} color={'gray.600'}>{session?.address_bairro}, {session?.address_cidade}</Text>
                        {session?.session_vertical_data_area &&
                            <Text fontWeight={'400'} fontSize={'14px'} color={'gray.600'}>
                                {session?.session_vertical_data_area} m<sup>2</sup>
                            </Text>}

                        <Text fontWeight={'400'} fontSize={'14px'} color={'gray.600'} mt={'3'}>Agendado por:</Text>
                        <Flex gap={'4'} mt={'1'}>
                            <Avatar name={session?.user?.name} size={'md'}/>
                            <Flex direction='column'>
                                <Box>
                                    <Text>{session?.user?.name}  </Text>
                                    {session?.scheduling_responsible_info_role && <Text textColor={'gray.500'} fontSize={'13px'}>({session?.scheduling_responsible_info_role})</Text>}
                                </Box>
                                <Text opacity={'0.6'}>Em {session?.created_at && new Date(session?.created_at).toLocaleDateString(
                                    "pt-BR",
                                    {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                    }
                                )} - {session?.created_at && format(new Date(session?.created_at), "HH:mm")} </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Divider my={'4'}/>

                <Flex mb={3}>
                    {session?.session_vertical_data_with_condo !== undefined && (
                        session.session_vertical_data_with_condo ? (
                            <Flex
                                bg="green.500"
                                rounded="10px"
                                textColor="white"
                                fontWeight="500"
                                p={1}
                                px={3}
                                align="center"
                                gap={2}
                            >
                                <CheckIcon />
                                <Text>Captar imagens no condomínio</Text>
                            </Flex>
                        ) : (
                            <Flex
                                bg="red.500"
                                rounded="10px"
                                textColor="white"
                                fontWeight="500"
                                p={1}
                                px={3}
                                align="center"
                                gap={2}
                            >
                                <CloseIcon />
                                <Text>Não captar imagens no condomínio</Text>
                            </Flex>
                        )
                    )}
                </Flex>


                <Flex flexDirection={'column'}>
                    <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'} mb={'3'}>Produção</Text>

                    <Flex gap={'2'} flexDirection={'column'}>

                        {session?.items.map(value =>
                            <>
                                <Flex gap={'3'}>
                                    <Flex p={'2'} bg={'#E3FFED'} borderRadius={'2xl'}>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 10.7906C7.86437 10.7906 8.60002 10.4921 9.20698 9.89518C9.81393 9.29822 10.1174 8.57468 10.1174 7.72455C10.1174 6.87442 9.81393 6.15088 9.20698 5.55392C8.60002 4.95697 7.86437 4.65849 7 4.65849C6.13563 4.65849 5.39997 4.95697 4.79302 5.55392C4.18607 6.15088 3.8826 6.87442 3.8826 7.72455C3.8826 8.57468 4.18607 9.29822 4.79302 9.89518C5.39997 10.4921 6.13563 10.7906 7 10.7906ZM1.33199 13.1598C0.959788 13.1598 0.644738 13.033 0.386843 12.7794C0.128948 12.5257 0 12.2159 0 11.8498V3.59931C0 3.23324 0.128948 2.92338 0.386843 2.66973C0.644738 2.41608 0.959788 2.28926 1.33199 2.28926H3.58219L4.94535 0.839844H9.05465L10.4178 2.28926H12.668C13.0402 2.28926 13.3553 2.41608 13.6132 2.66973C13.8711 2.92338 14 3.23324 14 3.59931V11.8498C14 12.2159 13.8711 12.5257 13.6132 12.7794C13.3553 13.033 13.0402 13.1598 12.668 13.1598H1.33199Z"
                                                fill="#00D99D"/>
                                            <path
                                                d="M8.96 7.83984C8.96 8.92232 8.08248 9.79984 7 9.79984C5.91752 9.79984 5.04 8.92232 5.04 7.83984C5.04 6.75737 5.91752 5.87984 7 5.87984C8.08248 5.87984 8.96 6.75737 8.96 7.83984Z"
                                                fill="#00D99D"/>
                                        </svg>
                                    </Flex>
                                    <Text my={'auto'}>{value.title}</Text>
                                </Flex>
                            </>
                        )}
                    </Flex>


                    <Text mt={'3'} color={'gray.500'} fontWeight={'500'} fontSize={'18px'}>Briefing pré-produção</Text>
                    <Flex
                        mt={'2'}
                        flexDirection={'column'}
                        bg={'gray.100'}
                        padding={'6'}
                        borderRadius={'xl'}
                    >
                        <Text fontWeight={'500'}>CARACTERÍSTICAS TÉCNICAS</Text>
                        <Text fontWeight={'300'}>{session?.briefing_pre}</Text>
                    </Flex>

                    <Text mt={'3'} color={'gray.500'} fontWeight={'500'} fontSize={'18px'}>Briefing pós-produção</Text>
                    <Flex
                        mt={'2'}
                        flexDirection={'column'}
                        bg={'gray.100'}
                        padding={'6'}
                        borderRadius={'xl'}
                    >
                        <Text fontWeight={'500'}>CARACTERÍSTICAS TÉCNICAS</Text>
                        <Text fontWeight={'300'}>{session?.briefing_pos}</Text>
                    </Flex>
                    {session?.form && session?.form.length > 0 &&
                    <>
                        <Text mt={'3'} color={'gray.500'} fontWeight={'500'} fontSize={'18px'}>Formulário:</Text>
                        <Flex
                            mt={'2'}
                            flexDirection={'column'}
                            bg={'gray.100'}
                            padding={'6'}
                            borderRadius={'xl'}
                        >
                            {session?.form.map(value =>
                                <Text fontWeight={'500'} gap={2} display={'flex'}>{value.label}: <Text fontWeight={'300'}> {value.value}</Text></Text>
                            )}
                        </Flex>
                    </>
                    }

                    {session?.delivery_for &&
                        <Flex gap={'3'} my={'3'}><Text fontSize={'18px'} fontWeight={'500'}>Prazo de entrega </Text><Text
                            fontSize={'18px'} color={'gray.500'} fontWeight={'500'}>
                            {session?.delivery_for && new Date(session?.delivery_for).toLocaleDateString(
                                "pt-BR",
                                {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                }
                            )}
                        </Text>
                        </Flex>}
                </Flex>

                <Divider/>

                <Flex flexDirection={'column'} gap={'4'}>
                    {session?.vertical_data_keys &&
                        <Flex mt={'1'} gap={1}>
                            <Text fontSize={'18px'} fontWeight={'500'}>Local das chaves: </Text>
                            <Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}> {session?.vertical_data_keys}</Text>
                        </Flex>
                    }
                    <Text mt={'1'} fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>Endereço da produção</Text>
                    <Flex gap={'4'}>
                        {session?.key_pickup_address &&
                            <>
                                <Flex as={'a'} target={'_blank'} href={session?.key_pickup_address_link} justifyContent={'center'} gap={1} alignItems={'center'} minW={'100px'} bg={'gray.100'} height={'100px'} borderRadius={'xl'} flexDirection={'column'}>
                                    <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9996 14.1138C11.6654 14.1138 12.2315 13.878 12.6979 13.4064C13.1643 12.9348 13.3975 12.3623 13.3975 11.6891C13.3975 11.0158 13.1643 10.4434 12.6979 9.97181C12.2315 9.50018 11.6654 9.26437 10.9996 9.26437C10.3337 9.26437 9.76762 9.50018 9.30122 9.97181C8.83479 10.4434 8.60158 11.0158 8.60158 11.6891C8.60158 12.3623 8.83479 12.9348 9.30122 13.4064C9.76762 13.878 10.3337 14.1138 10.9996 14.1138ZM10.9996 25.2704C13.821 22.7195 15.9537 20.2855 17.3978 17.9682C18.8419 15.6509 19.5639 13.6502 19.5639 11.9662C19.5639 9.34248 18.7373 7.19706 17.0842 5.52994C15.4311 3.86279 13.4028 3.02922 10.9996 3.02922C8.59627 3.02922 6.56806 3.86279 4.91492 5.52994C3.26177 7.19706 2.4352 9.34248 2.4352 11.9662C2.4352 13.6502 3.15724 15.6509 4.60133 17.9682C6.04541 20.2855 8.17815 22.7195 10.9996 25.2704ZM10.9996 28.0495C7.42803 24.9124 4.76519 22.0005 3.01103 19.3137C1.2569 16.6269 0.379837 14.1778 0.379837 11.9662C0.379837 8.60887 1.44883 5.93365 3.58683 3.94054C5.72483 1.94747 8.19573 0.950928 10.9996 0.950928C13.8034 0.950928 16.2743 1.94747 18.4123 3.94054C20.5503 5.93365 21.6193 8.60887 21.6193 11.9662C21.6193 14.1778 20.7422 16.627 18.9881 19.3137C17.2339 22.0005 14.5711 24.9124 10.9996 28.0495Z" fill="#97A4B6"/>
                                    </svg>
                                    <Text fontWeight={'500'} fontSize={'12px'} color={'gray.500'} as='u'>Ver no mapa</Text>
                                </Flex>


                                <Flex flexDirection={'column'}>
                                    <Text fontWeight={'500'} fontSize={'16px'}>Coleta de chave</Text>
                                    <Text fontWeight={'500'} fontSize={'14px'}
                                          color={'gray.600'}>{session?.key_pickup_address}</Text>
                                    <Text fontWeight={'500'} fontSize={'16px'} color={'gray.600'}>Responsável: {session?.key_pickup_contact} </Text>
                                </Flex>
                            </>
                        }
                    </Flex>
                    <Flex gap={'4'}>
                        <Flex as={'a'} target={'_blank'} href={session?.address_link} justifyContent={'center'} gap={1} alignItems={'center'} minW={'100px'} bg={'gray.100'} height={'100px'} borderRadius={'xl'} flexDirection={'column'}>
                            <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9996 14.1138C11.6654 14.1138 12.2315 13.878 12.6979 13.4064C13.1643 12.9348 13.3975 12.3623 13.3975 11.6891C13.3975 11.0158 13.1643 10.4434 12.6979 9.97181C12.2315 9.50018 11.6654 9.26437 10.9996 9.26437C10.3337 9.26437 9.76762 9.50018 9.30122 9.97181C8.83479 10.4434 8.60158 11.0158 8.60158 11.6891C8.60158 12.3623 8.83479 12.9348 9.30122 13.4064C9.76762 13.878 10.3337 14.1138 10.9996 14.1138ZM10.9996 25.2704C13.821 22.7195 15.9537 20.2855 17.3978 17.9682C18.8419 15.6509 19.5639 13.6502 19.5639 11.9662C19.5639 9.34248 18.7373 7.19706 17.0842 5.52994C15.4311 3.86279 13.4028 3.02922 10.9996 3.02922C8.59627 3.02922 6.56806 3.86279 4.91492 5.52994C3.26177 7.19706 2.4352 9.34248 2.4352 11.9662C2.4352 13.6502 3.15724 15.6509 4.60133 17.9682C6.04541 20.2855 8.17815 22.7195 10.9996 25.2704ZM10.9996 28.0495C7.42803 24.9124 4.76519 22.0005 3.01103 19.3137C1.2569 16.6269 0.379837 14.1778 0.379837 11.9662C0.379837 8.60887 1.44883 5.93365 3.58683 3.94054C5.72483 1.94747 8.19573 0.950928 10.9996 0.950928C13.8034 0.950928 16.2743 1.94747 18.4123 3.94054C20.5503 5.93365 21.6193 8.60887 21.6193 11.9662C21.6193 14.1778 20.7422 16.627 18.9881 19.3137C17.2339 22.0005 14.5711 24.9124 10.9996 28.0495Z" fill="#97A4B6"/>
                            </svg>
                            <Text fontWeight={'500'} fontSize={'12px'} color={'gray.500'} as='u'>Ver no mapa</Text>
                        </Flex>

                        <Flex flexDirection={'column'}>
                            <Text fontWeight={'500'} fontSize={'16px'}>Imóvel</Text>
                            <Text fontWeight={'500'} fontSize={'14px'} color={'gray.600'}>{session?.short_address}</Text>
                            <Text fontWeight={'500'} fontSize={'14px'} color={'gray.600'}>{session?.address_bairro}, {session?.address_cidade}</Text>
                            <Text fontWeight={'500'} fontSize={'16px'} color={'gray.600'}>Anfitrião: {session?.scheduling_responsible_info} </Text>
                        </Flex>
                    </Flex>


                    {session?.scheduling_responsible_info_extra &&
                        <Flex gap={1}>
                            <Text fontSize={'15px'} fontWeight={'500'}>observações: </Text>
                            <Text fontWeight={'300'} fontSize={'15px'} color={'gray.500'}> {session?.scheduling_responsible_info_extra}</Text>
                        </Flex>
                    }

                    {session?.status == String(SessionStatus.SCHEDULED) && session?.SessionCheckStartProvider == false &&
                        <Box className="w-full grid gap-2">
                            <Button onClick={onOpen} colorScheme="blue">
                                Checkin
                            </Button>
                            <Modal isOpen={isOpen} size="xs" onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader fontSize="lg" fontFamily="base">
                                        Confirmar Checkin
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody fontFamily="base">
                                        Podemos confirmar o checkin?
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button variant="tertiary" onClick={onClose}>
                                            Sair
                                        </Button>
                                        <Button
                                            variant="primary"
                                            mr={3}
                                            rightIcon={<ChevronRightIcon w={6} h={6} />}
                                            onClick={handleCheckIn}
                                        >
                                            Confirmar
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Box>
                    }

                    {session?.status == String(SessionStatus.PENDING) && session?.SessionCheckStartProvider == true && session?.SessionCheckEndProvider ==false &&
                        <Box className="w-full grid gap-2">
                            <Button onClick={onOpen} colorScheme="blue">
                                CheckOut
                            </Button>
                            <Modal isOpen={isOpen} size="xs" onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader fontSize="md" fontFamily="base">
                                        Relato sobre o agendamento
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody fontFamily="base" className="flex flex-col gap-6">
                                        <div className="flex flex-col gap-2">
                                            <Heading variant="bodyTextMedium" color="gray.500">
                                                Faltou captar as imagens de algum ambiente?
                                            </Heading>
                                            <Textarea
                                                onChange={(value) => {
                                                    setLacking_areas(value.target.value);
                                                }}
                                                size="xs"
                                                placeholder=""
                                            />
                                        </div>
                                        <Divider/>
                                        <div className="flex flex-col gap-2">
                                            <Heading variant="bodyTextMedium" color="gray.500">
                                                Registre aqui se houve alguma dificuldade, situação incomum, ou informação sobre essa produção.
                                            </Heading>
                                            <Textarea
                                                onChange={(value) => {
                                                    setNotes(value.target.value);
                                                }}
                                                size="xs"
                                                placeholder=""
                                            />
                                        </div>
                                        <Divider/>
                                        <Flex flexDirection={'column'} alignSelf={'center'}>
                                            <Text>Avaliar produção:</Text>

                                            <Rating style={{ maxWidth: 125 }} value={Number(rating)} onChange={setRating} />
                                        </Flex>
                                        <Heading size="xsmall" color="low.pure">
                                            Podemos confirmar o checkout?
                                        </Heading>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button variant="tertiary" onClick={onClose}>
                                            Sair
                                        </Button>
                                        <Button
                                            variant="primary"
                                            bg="secondary.pure"
                                            color={'white'}
                                            mr={3}
                                            onClick={handleCheckOut}
                                            rightIcon={<ChevronRightIcon w={6} h={6} />}
                                        >
                                            Confirmar
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Box>
                    }
                </Flex>

                <Divider my={'4'} mt={'7'}/>

                <Flex flexDirection={'column'} gap={'4'} mb={8}>
                    <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>Finalização</Text>
                    <Button
                        colorScheme={'whatsapp'}
                        rightIcon={<ChevronRightIcon/>}
                        as={"a"}
                        href={whatsAppInRoute}
                        target={"_blank"}
                    >
                        <Text>Avisar que estou a caminho</Text>
                    </Button>
                    <Button
                        colorScheme="blue"
                        as={"a"}
                        href={whatsAppContact}
                        target={"_blank"}
                    >
                        <Text>Contatar agendador da produção</Text>
                    </Button>
                    <SessionCancelModal
                        id={String(session?.id)}
                        variantButton="secondary"
                        status={String(SessionStatus.CANCELED_CHARGED)}
                        buttonText={"Cancelar produção"}
                    />


                    <Report session_id={String(session?.id)}/>

                    <Flex gap={2} p={3} alignItems={'center'} bg={'green.200'} w={'100%'} flexDirection={'column'}
                          borderRadius={'lg'}>
                        <Text w={'250px'} fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>Para fazer a entrega da
                            produção acesse o Studio Piperz na versão desktop.</Text>
                        <Button w={'120px'} color={'white'} bg={'#4D5562'} onClick={copylink}>Copiar link</Button>
                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Box>
    );
};

export default DeliverableView;